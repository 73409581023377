import React, { useState } from 'react';
import Button from '../components/Button/Button';
import PageLayout from '../components/Layout/PageLayout';
import ResetPasswordPopup from './ResetPasswordPopup';
import { confirmPasswordChange } from '../utils/passwordUtils';

import pdfManual from '../res/manual.pdf';

export default function SettingsPage() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  return (
    <PageLayout pageClassName="settings-page" title="Settings">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <Button href={pdfManual}>Download instructions</Button>
        </div>
        <div className="flex flex-col gap-3">
          <h2>User settings</h2>
          <Button onClick={handleOpenPopup}>Reset password</Button>
        </div>
      </div>

      {isPopupOpen && (
        <ResetPasswordPopup
          onClose={handleClosePopup}
          onSubmit={(newPassword, confirmPassword) => {
            confirmPasswordChange(newPassword, confirmPassword);
            handleClosePopup();
          }}
        />
      )}
    </PageLayout>
  );
}
