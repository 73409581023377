import React from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import CreatePage from './pages/create';
import OverviewPage from './pages/overview';
import HistoryPage from './pages/history';
import SettingsPage from './pages/settings';
import AuthCallback from './pages/AuthCallback';

import './app.scss';
import UsersPage from './pages/UsersPage';
import PasswordRetrievePage from './pages/PasswordRetrieve';
import PasswordSetupPage from './pages/PasswordSetup';

function App() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage onLogin={() => navigate('/app/create')} />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/password-retrieve" element={<PasswordRetrievePage />} />
        <Route path="/password-setup" element={<PasswordSetupPage />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/app/create" element={<CreatePage />} />
        <Route path="/app/overview" element={<OverviewPage />} />
        <Route path="/app/profile" element={<HistoryPage />} />
        <Route path="/app/settings" element={<SettingsPage />} />
        <Route path="/app/users" element={<UsersPage />} />
        <Route path="*" element={<div>404</div>} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
