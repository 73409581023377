import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import {
  BsCalendar3,
  BsPerson,
  BsWrench,
  BsHouseFill,
} from 'react-icons/bs';
import { toast } from 'react-toastify';
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem';
import Logo from '../Logo/Logo';
import Button from '../Button/Button';
import { useLogout } from '../../hooks/useLogout';
import { useUser } from '../../hooks/useUser';
import './page-layout.scss';

export default function PageLayout({
  includeMenu = true,
  title,
  pageClassName,
  children,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user, isLoading, error } = useUser();
  const { mutate: logout, isLoading: isLoggingOut } = useLogout();

  // Temporary fix for illegal navigation through app - replace with auth protected routes
  useEffect(() => {
    const token = localStorage.getItem('token');

    const afterLoginPagesPath = '/app/';

    if (!token && location.pathname.includes(afterLoginPagesPath)) {
      toast.warning('You need to be logged in to access this page!');
      navigate('/');
    }

    if (token && !location.pathname.includes(afterLoginPagesPath)) {
      navigate('/app/create');
    }

    const handlePopState = () => {
      const currentToken = localStorage.getItem('token');
      if (!currentToken) {
        toast.warning('You cannot navigate back after logout!');
        navigate('/');
      }
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  // Temporary fix for illegal navigation through app - replace with role-based protected routes
  useEffect(() => {
    if (isLoading) return;
    if (error) {
      return;
    }

    if (user?.role !== 'admin' && window.location.pathname === '/app/users') {
      navigate('/app/create');
      toast.error('You do not have access to that page');
    }
  }, [user, isLoading, error, navigate]);

  return (
    <div className={clsx('layout', includeMenu && 'menu-page-layout')}>
      <div className="menu-sidebar">
        {includeMenu && (
          <Menu>
            <Logo className="logo" />
            <MenuItem label="Create a meeting" link="/app/create" icon={<AiOutlinePlusCircle />} />
            <MenuItem label="Upcoming meetings" link="/app/overview" icon={<BsCalendar3 />} />
            <MenuItem label="Meeting history" link="/app/profile" icon={<BsPerson />} />
            <MenuItem label="Settings" link="/app/settings" icon={<BsWrench />} />
            {user?.role === 'admin' && (
              <MenuItem label="Users" link="/app/users" icon={<BsHouseFill />} />
            )}
            <div className="menu-bottom">
              <Button onClick={logout} disabled={isLoggingOut}>
                Sign out
              </Button>
            </div>
          </Menu>
        )}
      </div>
      <div className="content">
        <div className={clsx('page', pageClassName)}>
          {title && <h1 className="title">{title}</h1>}
        </div>
        {children}
      </div>
      <div className="right-filler" />
    </div>
  );
}
