import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  fetchUsers,
  addUser,
  deleteUser,
  updateUserPassword,
  disableUser,
} from '../api';
import PageLayout from '../components/Layout/PageLayout';
import Button from '../components/Button/Button';
import './users-page.scss';

export default function UsersPage() {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ email: '', password: '', role: 'standard-user' });
  const [loading, setLoading] = useState(true);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [resetPassword, setResetPassword] = useState('');
  const [resetConfirmPassword, setResetConfirmPassword] = useState('');

  const loadUsers = async () => {
    try {
      setLoading(true);
      const usersData = await fetchUsers();
      setUsers(usersData);
      setLoading(false);
    } catch (fetchError) {
      toast.error('Error fetching users!');
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      await addUser(newUser);
      loadUsers();
      toast.success('User added successfully!');
      setNewUser({ email: '', password: '', role: 'standard-user' });
    } catch (addError) {
      if (addError.response?.status === 409) {
        toast.warning('This email is already in use.');
      } else {
        toast.error('Error adding user!');
      }
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      loadUsers();
      toast.success('User deleted successfully!');
    } catch (deleteError) {
      toast.error('Error deleting user!');
    }
  };

  const handleUpdatePassword = async (userId, newPassword) => {
    try {
      await updateUserPassword(userId, newPassword);
      loadUsers();
      toast.success('Password updated successfully!');
    } catch (updateError) {
      toast.error('Error updating password!');
    }
  };

  const handleDisableUser = async (userId) => {
    try {
      await disableUser(userId);
      loadUsers();
      toast.success('User status updated successfully!');
    } catch (disableError) {
      toast.error('Error updating user status!');
    }
  };

  const openResetPasswordPopup = (userId) => {
    setSelectedUserId(userId);
    setShowResetPasswordPopup(true);
  };

  const closeResetPasswordPopup = () => {
    setShowResetPasswordPopup(false);
    setSelectedUserId(null);
    setResetPassword('');
    setResetConfirmPassword('');
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{6,}$/;
    return passwordRegex.test(password);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (resetPassword !== resetConfirmPassword) {
      toast.warning('Passwords do not match!');
      return;
    }

    if (!validatePassword(resetPassword)) {
      toast.warning('Password must be at least 6 characters long, contain a number, an uppercase letter, a lowercase letter, and a special character.');
      return;
    }

    if (selectedUserId) {
      try {
        await handleUpdatePassword(selectedUserId, resetPassword);
        toast.success('Password updated successfully!');
        closeResetPasswordPopup();
      } catch (error) {
        toast.error('Error updating password. Please try again.');
      }
    }
  };

  const standardUsers = users.filter((user) => user.role === 'standard-user');

  let content;

  if (loading) {
    content = <tr><td colSpan="3">Loading users...</td></tr>;
  } else if (Array.isArray(standardUsers) && standardUsers.length > 0) {
    content = (
      <>
        {standardUsers.map((user) => (
          <tr key={user.id}>
            <td>{user.email}</td>
            <td>{user.role}</td>
            <td>
              <Button type="button" onClick={() => handleDeleteUser(user.id)}>Delete</Button>
              <Button type="button" onClick={() => openResetPasswordPopup(user.id)}>Update Password</Button>
              <Button type="button" onClick={() => handleDisableUser(user.id)}>
                {user.disabled ? 'Enable' : 'Disable'}
              </Button>
            </td>
          </tr>
        ))}
      </>
    );
  } else {
    content = <tr><td colSpan="3">No users available</td></tr>;
  }

  return (
    <PageLayout>
      <div className="users-page">
        <h1>Users</h1>
        <form onSubmit={handleAddUser}>
          <input
            type="email"
            placeholder="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            required
          />
          <select
            value={newUser.role}
            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
          >
            <option value="standard-user">User</option>
            <option value="admin">Admin</option>
          </select>
          <Button type="submit" className="button">Add User</Button>
        </form>
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {content}
          </tbody>
        </table>
        {showResetPasswordPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h3>Reset Password</h3>
              <form onSubmit={handleResetPassword}>
                <input
                  type="password"
                  placeholder="New Password"
                  value={resetPassword}
                  onChange={(e) => setResetPassword(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={resetConfirmPassword}
                  onChange={(e) => setResetConfirmPassword(e.target.value)}
                  required
                />
                <Button className="confirm-button" type="submit">Confirm</Button>
                <Button className="cancel-button" type="button" onClick={closeResetPasswordPopup}>Cancel</Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
}
