/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import clsx from 'clsx';

import { MdOutlineContentCopy } from 'react-icons/md';

import Button from '../Button/Button';

import meetingTypes from './meetingTypes';

import './created-meetings.scss';

// TODO: move out
export function MeetingInfo({ meeting, tabular }) {
  const { meetingType, isPrimary, uri } = meeting;

  if (!Object.keys(meetingTypes).includes(meetingType)) {
    return null;
  }

  const { name: meetingName, icon, hrefFunction } = meetingTypes[meetingType];

  return (
    <div className={clsx('meeting-info-wrapper', tabular && 'tabular')}>
      <div className={clsx('meeting-info', isPrimary && 'primary')}>
        <img src={icon} alt={meetingName} />
        <div className="meeting-link-wrapper">
          <a href={hrefFunction(uri)}>{uri}</a>
        </div>
      </div>
      <Button small onClick={() => navigator.clipboard.writeText(uri)}>
        <MdOutlineContentCopy />
      </Button>
    </div>
  );
}

export default function CreatedMeetings({ meetingData }) {
  if (!meetingData) {
    return null;
  }

  const meetings = meetingData?.externalMeetings?.sort((m) => (m.isPrimary ? -1 : 1));

  return (
    <div className="created-meetings-wrapper gap-4">
      <h2>
        Successfully created the following meetings:
      </h2>
      <div className="created-meetings">
        {meetings?.map((meeting) => <MeetingInfo meeting={meeting} />) }
      </div>
    </div>
  );
}
