import React from 'react';

import { Calendar as Cal } from 'react-calendar';

import './calendar.scss';

export default function Calendar({
  className, style, onChange, value,
}) {
  return <Cal className={className} style={style} onChange={onChange} value={value} />;
}
