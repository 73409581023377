import webexLogo from '../../res/webex_logo.png';
import teamsLogo from '../../res/teams_logo.png';
// import meetLogo from '../../res/meet_logo.png';
import zoomLogo from '../../res/zoom_logo.png';

const meetingTypes = Object.freeze({
  webex: { name: 'Webex', icon: webexLogo, hrefFunction: (uri) => `webexteams://meet?sip=${uri}` },
  'microsoft-teams': { name: 'Teams', icon: teamsLogo, hrefFunction: (uri) => uri },
  zoom: { name: 'Zoom', icon: zoomLogo, hrefFunction: (uri) => uri },
  // meet: { name: 'Meet', icon: meetLogo, hrefFunction: (uri) => uri },
});

export default meetingTypes;
