import clsx from 'clsx';
import React from 'react';

import './logo.scss';

export default function Logo({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327.4 405.72" className={clsx('logo', className)}>
      <path fill="none" stroke="black" strokeWidth="25px" className="logo-arc-1" d="M200.26,203.72h18.56c52.5,0,95.06-42.56,95.06-95.06h0c0-52.5-42.56-95.06-95.06-95.06h-18.56" />
      <path fill="none" stroke="black" strokeWidth="25px" className="logo-arc-2" d="M127.22,393.51h-18.56c-52.5,0-95.06-42.56-95.06-95.06h0c0-52.5,42.56-95.06,95.06-95.06h18.56" />
      <path d="M163.66 405.72L163.66 0.15" className="cls-1" stroke="#dc2f27" strokeWidth="25px" />
    </svg>
  );
}
