import React from 'react';

import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

import './menu-item.scss';

export default function MenuItem({
  icon,
  label,
  link,
}) {
  return (
    <NavLink
      className={({ isActive }) => clsx('menu-item', isActive && 'active')}
      to={link}
    >
      {icon}
      {' '}
      <span className="label">
        {label}
      </span>
    </NavLink>
  );
}
