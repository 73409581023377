import React from 'react';

import clsx from 'clsx';

import { MdOutlineDeleteOutline, MdStop } from 'react-icons/md';
import { MeetingInfo } from '../CreateMeetingForm/CreatedMeetings';

import './meeting-list.scss';
import { getMeetingLongDate } from '../../utils/get-meeting-long-date';
import { getMeetingStartTime } from '../../utils/get-meeting-start-time';
import { getMeetingEndTime } from '../../utils/get-meeting-end-time';

// TODO: clean this up
const idWidth = 30;
const statusWidth = 60;
const dateWidth = 100;
const timeWidth = 100;
const primaryWidth = 100;

function MeetingDeleteButton({ status, onClick }) {
  if (status !== 'scheduled' && status !== 'running') {
    return null;
  }

  return (
    <button
      aria-label="delete meeting"
      className="delete-button"
      type="button"
      onClick={onClick}
    >
      {
        status === 'running'
          ? <MdStop />
          : <MdOutlineDeleteOutline />
        }
    </button>
  );
}

export default function MeetingList({ meetings, onDeleteClick }) {
  if (!meetings?.length) {
    return <p>No recorded meetings</p>;
  }

  return (
    <div className="meeting-list flex flex-col gap-6 items-center pb-8">
      <header className={clsx('flex gap-6 items-center w-100')}>
        <p style={{ width: idWidth }}>ID</p>
        <p style={{ width: statusWidth }}>Status</p>
        <p style={{ width: dateWidth }}>Date</p>
        <p style={{ width: timeWidth }}>Start - End</p>
        <p style={{ width: primaryWidth }}>Primary</p>
        <p className="external-meetings-header">External meetings</p>
      </header>

      {meetings.map(({
        status, id, externalMeetings, startTime, durationMinutes,
      }) => {
        if (!externalMeetings) {
          return null;
        }

        return (
          <div className={clsx('meeting-wrapper gap-6 items-center', status, startTime, durationMinutes)} key={id}>
            <div className="meeting-info flex justify-center gap-6 items-center">
              <h3 className="meeting-id" style={{ width: idWidth }}>
                {id}
              </h3>
              <p style={{ width: statusWidth }}>
                {status}
              </p>
              <p style={{ width: dateWidth }}>
                {getMeetingLongDate(startTime)}
              </p>
              <p style={{ width: timeWidth }}>
                {getMeetingStartTime(startTime)}
                -
                {getMeetingEndTime(startTime, durationMinutes)}

              </p>
            </div>
            <div className="external-meetings flex w-full gap-6">
              {externalMeetings?.map((meeting) => <MeetingInfo key={meeting.id} meeting={meeting} tabular />)}
            </div>
            <MeetingDeleteButton status={status} onClick={() => onDeleteClick(id)} />
          </div>
        );
      })}
    </div>
  );
}
